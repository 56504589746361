
//note(@duncanmid): offline service worker


/* if ('serviceWorker' in navigator) {
	navigator.serviceWorker.register('/service-worker.js').then(function(registration) {
    
    console.log('ServiceWorker registration successful with scope: ', registration.scope);
}).catch(function(err) {
    
    	console.log('ServiceWorker registration failed: ', err);
    });
} */





//note(@duncanmid): cookie notice

/* window.addEventListener("load", function() {

	window.cookieconsent.initialise({
		"palette": {
			"popup": {
				"background": "rgba(62,62,88,.85)"
			},
			"button": {
				"background": "#3fb8b9",
				"text": "#fff"
			}
		},
		"theme": "classic",
		"content": {
			"href": "//www.midwinter-dg.com/privacy.html",
			"message": "midwinter-dg.com uses cookies to ensure you get the best experience on our site:",
			"dismiss": "Got it!",
			"link": "Privacy"
		}
	})
}); */






//note(@duncanmid): header search field

jQuery(function() {
	
	var searchEl 	= jQuery('#search-input');
	var labelEl 	= jQuery('#search-label');


	labelEl.click(function() {
		
		if( searchEl.hasClass('focus') ) {
		
			searchEl.removeClass('focus');
			labelEl.removeClass('active');
	
		} else {
		
			searchEl.addClass('focus');
			labelEl.addClass('active');
		}
	});
	
	
	jQuery(document).on('click', function( e ) {
		
		var clickedID = e.target.id;
		
		if( clickedID !== 'search-button' && clickedID !== 's' ) {

			
			if( searchEl.hasClass('focus') ) {
		
				searchEl.removeClass('focus');
				labelEl.removeClass('active');
			
			}
		}
	});
});






//note(@duncanmid): match height

jQuery(function() {
	
	jQuery('.software-item, .music-item, .resource-item, .featured-article > article').matchHeight();
});





//note(@duncanmid): on window resize

jQuery(function() {

	var resizeEnd;

	jQuery(window).on('resize', function() {

		clearTimeout(resizeEnd);

		resizeEnd = setTimeout(function() {
			
			jQuery(window).trigger('resizeEnd');
		
		}, 500);
	});
});


jQuery(window).on('resizeEnd', function() {

	var width = jQuery(window).width();
	
	
	if(width > 991) {
	
		jQuery('.menu').show();
	
	} else {
		
		jQuery('.menu').hide();
	}
});






//NOTE(@duncanmid): scroll to anchors

jQuery(function() {
	jQuery('a:not(.no-scroll)').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var target = jQuery(this.hash);
			target = target.length ? target : jQuery('[name=' + this.hash.slice(1) +']');
			if (target.length) {
				jQuery('html, body').animate({
					scrollTop: target.offset().top
				}, 1000);
				return false;
			}
		}
	});
});






//note(@duncanmid): archive select menu

function openDir( form ) {

	var newIndex = form.arc.selectedIndex;
	if ( newIndex == 0 ) { } else {

		cururl = form.arc.options[ newIndex ].value;
		window.location.assign( cururl );
	}
}






//note(@duncanmid): click this

function clickthis() {
	var clickthis = jQuery('.clickthis');
	clickthis.each(function(){
		var $this = jQuery(this);
		$this.css('cursor','pointer');
		$this.find('a').click(function(event){event.preventDefault();});
		$this.click(function(){window.location.href=jQuery('a',this).attr('href');});
	});
}






//note(@duncanmid): yt video

jQuery(document).on('click','.js-videoPoster',function(ev) {
	ev.preventDefault();
	var $poster = jQuery(this);
	var $wrapper = $poster.closest('.js-videoWrapper');
	videoPlay($wrapper);
});

// play the targeted video (and hide the poster frame)
function videoPlay($wrapper) {
	var $iframe = $wrapper.find('.js-videoIframe');
	var src = $iframe.data('src');
	// hide poster
	$wrapper.addClass('videoWrapperActive');
	// add iframe src in, starting the video
	$iframe.attr('src',src);
}

// stop the targeted/all videos (and re-instate the poster frames)
function videoStop($wrapper) {
	// if we're stopping all videos on page
	if (!$wrapper) {
	var $wrapper = jQuery('.js-videoWrapper');
	var $iframe = jQuery('.js-videoIframe');
	// if we're stopping a particular video
	} else {
	var $iframe = $wrapper.find('.js-videoIframe');
	}
	// reveal poster
	$wrapper.removeClass('videoWrapperActive');
	// remove youtube link, stopping the video from playing in the background
	$iframe.attr('src','');
}






//note(@duncanmid): youtube channel

/* jQuery(function() {
	
	jQuery('#my-channel').spidochetube({
		key 			: 'AIzaSyAJVeUUYcRqCItRIYvLMPfddf7zrkMTSyg',
		id 				: 'PLbC4CSl8rrexUjdF0-ss52D4XdDGNQMlX',
		max_results 	: 8,
		paging 			: 'loadmore',
		scroll_duration : 500
	});
}); */





//note(@duncanmid): form validation

validate.init();





//note(@duncanmid): map init


/* if( jQuery('#location-map').length ) {

	var map;
	function initialize() {
	
		var mapOptions = {
			mapTypeId: google.maps.MapTypeId.TERRAIN,
			mapTypeControl: true,
			mapTypeControlOptions: {
				style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
				position: google.maps.ControlPosition.LEFT_TOP
			},
			zoom: 7,
			zoomControl: true,
			zoomControlOptions: {
				style: google.maps.ZoomControlStyle.LARGE,
				position: google.maps.ControlPosition.RIGHT_BOTTOM
			},
			streetViewControl: false,
			scaleControl: false,
			center: new google.maps.LatLng(43.93809700000001, 12.741729999999961)
		};
		
		map = new google.maps.Map(document.getElementById('location-map'),
			mapOptions);
		
		var mapStyles = [
			{
				"featureType": "landscape",
				"stylers": [
					{ "visibility": "on" }
				]
			},{
				"featureType": "water",
				"stylers": [
					{ "visibility": "on" }
				]
			},{
				"featureType": "water",
				"elementType": "labels",
				"stylers": [
					{ "visibility": "on" }
				]
			},{
				"featureType": "administrative",
				"stylers": [
					{ "visibility": "on" }
				]
			},{
				"featureType": "administrative",
				"elementType": "labels",
				"stylers": [
					{ "visibility": "on" }
				]
			},{
				"featureType": "poi",
				"stylers": [
					{ "visibility": "on" }
				]
			},{
				"featureType": "road",
				"stylers": [
					{ "visibility": "on" }
				]
			},{
				"featureType": "transit",
				"stylers": [
					{ "visibility": "on" }
				]
			}
		];
		
		map.setOptions({styles: mapStyles});
		
		var infoContent = '<div class="window-content"><h3>Midiwnter Duncan Grant</h3><p>Piazza Donatello<br>61012 Gradara (PU)<br>Italia</p></div>';
		
		var infowindow = new google.maps.InfoWindow({
			content: infoContent
		});
		
		var icon = {
			path: 'M16.5,51s-16.5-25.119-16.5-34.327c0-9.2082,7.3873-16.673,16.5-16.673,9.113,0,16.5,7.4648,16.5,16.673,0,9.208-16.5,34.327-16.5,34.327zm0-27.462c3.7523,0,6.7941-3.0737,6.7941-6.8654,0-3.7916-3.0418-6.8654-6.7941-6.8654s-6.7941,3.0737-6.7941,6.8654c0,3.7916,3.0418,6.8654,6.7941,6.8654z',
			anchor: new google.maps.Point(16.5, 51),
			fillColor: '#0D8DC7',
			fillOpacity: 1,
			strokeWeight: 3,
			strokeColor: '#FFFFFF',
			strokeOpacity: 0.35,
			scale: 1
		};
		
		var marker = new google.maps.Marker({
			position: new google.maps.LatLng(43.93809700000001, 12.741729999999961),
			map: map,
			icon: icon,
			title: 'Midwinter Duncan Grant - Web Designer / Front-End Developer / Wordpress Developer'
		});
		
		google.maps.event.addListener(marker, 'click', function() {
			infowindow.open(map,marker);
		});
		
		infowindow.open(map, marker);
		
		
			//google.maps.event.addDomListener(window, 'load', initialize);
		
		function checkResize() {
		
			var center = map.getCenter();
			google.maps.event.trigger(map, 'resize');
			map.setCenter(center);
		}
		
		window.onresize = checkResize;
	}
}
 */



//note(@duncanmid): docready

jQuery(document).ready(function() {
	
	clickthis();
	
	var mixFilter = jQuery('.filtered');
	if ( mixFilter.length ) mixitup(mixFilter);
	
	jQuery(function(){
		
		var $filterSelect = jQuery('.filters-mobile');
  
		$filterSelect.on('change', function(){
			
			mixFilter.mixItUp('filter', this.value);
		});
	});

	
	
	//note(@duncanmid): menu and download link on scroll

	var itemDownload 	= jQuery('.item-download').offset().top;
	
	jQuery(window).scroll(function() {
		
		var height 	= jQuery(window).scrollTop();
		
		
		if ( height  > 20 ) {
		
			jQuery('#fixed-bar').addClass('scrolled');
		
		} else {
		
			jQuery('#fixed-bar').removeClass('scrolled');
		}
		
		if ( height > (itemDownload - 80) ) {
			
			jQuery('.item-download').addClass('fixed');
			
		} else {
			
			jQuery('.item-download').removeClass('fixed');
		}	
	});
	
	
	
	//note(@duncanmid): responsive menu

	jQuery('#toggle-menu').off('click').on('click', function() {
		
		jQuery('.menu').fadeToggle();
	});
	
	//note(@duncanmid): menu anchor links
	
	jQuery('.toggle-menu > a').click( function() {
		
		jQuery('.menu').fadeOut();
	});
	
	//note(@duncanmid): open map
	
	jQuery('#location').click( function(e) {
		
		e.preventDefault();
		jQuery('#location-map').css({ 'background-image': 'url("https://www.midwinter-dg.com/wp-content/themes/midwinter-dg-2017/images/theme/map.jpg")' });
		jQuery('#map-overlay').fadeIn('fast');
	});
	
	
	
	//note(@duncanmid): close map
	
	jQuery('#close-map').click( function(e) {
		
		e.preventDefault();
			

		jQuery('#map-overlay').fadeOut('fast');
	});
	
});
